/* header */
.header {
  display: block;
  max-width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2.5em;
  text-decoration: none;
  margin-bottom: 7px;
  line-height: normal;
}

.header .logo-name {
  padding: 0 10px;
  font-family: logoFont;
}

.btnfos-2 {
  letter-spacing: 0;
}

.btnfos-2:hover,
.btnfos-2:active {
  letter-spacing: 5px;
}

.btnfos-2:after,
.btnfos-2:before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 280ms ease-in-out;
  transition: all 280ms ease-in-out;
  width: 0;
}

.btnfos-2:hover:after,
.btnfos-2:hover:before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-color: #ea5455;
  -webkit-transition: width 350ms ease-in-out;
  transition: width 350ms ease-in-out;
  width: 100%;
}

.btnfos-2:hover:before {
  bottom: auto;
  top: 0;
  width: 100%;
}

/* menu */
.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.menu-btn {
  letter-spacing: 0;
}

/* menu icon */
.header .menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #ea5455;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #ea5455;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */
@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}
