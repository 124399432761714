.footer-text {
  text-align: center;
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-div {
  margin-top: 2rem;
}

@media (max-width: 400px) {
  .footer-text {
    font-size: 14px;
  }
}

@media (min-width: 401px) and (max-width: 700px) {
  .footer-text {
    font-size: 14px;
  }

  .footer-div {
    margin-top: 8rem;
  }
}

@media (min-width: 701px) and (max-width: 1030px) {
  .footer-text {
    font-size: 14px;
  }

  .footer-div {
    margin-top: 32rem;
  }
}
