#loading {
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 25px);
  z-index: 9999;
}
.uil-ripple {
  position: absolute;
  left: calc(50% - 90px);
  top: calc(50% - 90px);
  opacity: 0.5;
  z-index: 999;
}

.ld-l {
  opacity: 0;
  animation: ld-in 3s 0s ease infinite;
}
.ld-o {
  opacity: 0;
  animation: ld-in 3s 0.1s ease infinite;
}
.ld-a {
  opacity: 0;
  animation: ld-in 3s 0.2s ease infinite;
}
.ld-d {
  opacity: 0;
  animation: ld-in 3s 0.3s ease infinite;
}
.ld-i {
  opacity: 0;
  animation: ld-in 3s 0.4s ease infinite;
}
.ld-n {
  opacity: 0;
  animation: ld-in 3s 0.5s ease infinite;
}
.ld-g {
  opacity: 0;
  animation: ld-in 3s 0.6s ease infinite;
}

@keyframes ld-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
