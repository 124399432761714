.experience-card {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card-title {
  font-family: "Google Sans Medium";
  font-size: 30px;
}

.card-subtitle {
  font-family: "Google Sans Medium";
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.card-img {
  width: 200px;
  height: auto;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  margin-left: 50px;
  animation: rotateY 1s;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;

  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;

  -o-transition: 1s;
  -o-transform-style: preserve-3d;

  transition: 1s;
  transform-style: preserve-3d;
}

.card-img:hover {
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

@keyframes rotateY {
  to {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.body-header {
  max-width: inherit;
  display: flex;
  border-radius: 7px 7px 0px 0px;
  padding: 10px;
}

.body-header-title {
  align-items: flex-start;
  width: 80%;
}

.body-header-duration {
  width: 20%;
}
.duration {
  font-family: "Google Sans Regular";
  font-size: 20px;
  padding-right: 10px;
  float: right;
}

.body-content {
  max-width: inherit;
  border-radius: 0px 0px 7px 7px;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.content-list {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Google Sans Regular";
}

.card-body {
  border-bottom: 10px solid #ea5455;
  border-left: 1px solid #ea5455;
  border-right: 10px solid #ea5455;
  border-top: 1px solid #ea5455;
  border-radius: 7px;
  width: 90%;
  margin: 30px;
  margin-left: 0px;
  box-shadow: none;
  transition: all 0.3s linear;
  transform: translateY(0) scale(0.9, 0.9);
  -webkit-transform: translateY(0) scale(0.9, 0.9);
}

.card-body:hover,
.card-body:active {
  transform: translateY(-20px) scale(1, 1);
  -webkit-transform: translateY(-20px) scale(1, 1);
}

@media (max-width: 768px) {
  .experience-card {
    flex-direction: column;
  }

  .body-header {
    flex-direction: column;
  }

  .body-header-title {
    width: 100%;
  }

  .body-header-duration {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .duration {
    padding: 0px;
    margin: 0px;
    float: left;
  }

  .card-img {
    width: 200px;
    margin-left: 0px;
  }

  .card-body {
    width: 100%;
    margin-left: 25px;
  }

  .card-title {
    font-size: 20px;
  }

  .card-subtitle {
    font-size: 16px;
  }
}
