.browser-alert {
  margin: 0;
  padding: 1rem;
  background-color: #222;
  color: white;
}

.browser-alert a {
  color: inherit;
}

@supports (object-fit: cover) and (display: grid) {
  .browser-alert {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

.blog-post-teasers {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: center;
}

.blog-post-teasers > * {
  flex: 1 1 300px;
  align-self: flex-start;
  padding: 1rem;
}

.blog-post-teaser figure {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 30% 0;
  cursor: pointer;
}

.blog-post-teaser figure::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.blog-post-teaser figure:hover img {
  opacity: 0.25;
  transform: scale(1.3);
}

.blog-post-teaser figure:hover figcaption {
  opacity: 1;
  transform: translateY(0);
}

.blog-post-teaser figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  text-align: center;
  opacity: 0;
  transform: translateY(1rem);
  transition: all 0.3s ease-in-out;
}

.blog-post-teaser figcaption a {
  font-size: 18px;
  text-decoration: none;
  color: #e5e5e5;
}

.blog-post-teaser figcaption h1 {
  background-color: #ea5455;
  border: 1px solid #ea5455;
  color: #e5e5e5;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 13px 22px;
  margin-right: 0px;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
}

.blog-post-teaser figcaption h1:hover {
  background-color: #e5e5e5;
  color: #ea5455;
  transition: ease-in 0.3s;
}

.blog-post-teaser img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in-out;
}
