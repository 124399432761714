@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

form {
  max-width: 700px;
  margin: 50px auto;
}

.feedback-input {
  color: #e5e5e5;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background-color: #1b2836;
  border: none;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-text-fill-color: #e5e5e5 !important;
    -webkit-box-shadow: 0 0 0 30px #1b2836 inset !important;
}

.feedback-input:focus + .line {
  width: 100%;
  background-color: #ea5455;
}

.form-group .line {
  height: 2px;
  width: 0px;
  position: absolute;
  background-color: darkgrey;
  display: inline-block;
  transition: 0.3s width ease-in-out;
  position: relative;
  top: -31px;
}

.form-group .error-icon {
  float: right;
  position: relative;
  top: 33px;
  right: 15px;
  color: #c0392b;
}

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

.error-message {
  color: #c0392b;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: -10px;
}

[type="submit"] {
  background-color: #ea5455;
  border: solid 1px #ea5455;
  color: #e5e5e5;
  font-weight: 500;
  line-height: 1.1;
  width: 100%;
  padding: 13px 22px;
  margin-right: 0px;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
  outline: none;
}

[type="submit"]:hover {
  background-color: #17212e;
  color: #e5e5e5;
  transition: ease-in 0.3s;
}

[type="submit"]:disabled {
  background: #2ecc71;
  cursor: default;
  border: none;
}

@media (max-width: 768px) {
  form {
    max-width: 350px;
    margin: 50px auto;
  }
}
