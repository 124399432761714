.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  margin-top: 80px;
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 35px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.landingpic-wrapper {
  position: relative;
}

.greeting-image-div > * {
  position: absolute;
  margin-top: 20px;
  margin-left: 100px;
  z-index: 2;
}

.my-pic {
  width: 56%;
  padding-bottom: 53%;
}

.square-shape {
  position: absolute;
  margin-top: 150px;
  margin-left: 290px;
  width: 53%;
  padding-bottom: 53%;
  border: 15px solid #ea5455;
  animation: loader 3s ease;
  z-index: 1;
  animation-delay: 1s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .landingpic-wrapper {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 905px) {
  .greeting-image-div > * {
    margin-top: 80px;
    margin-left: 100px;
  }

  .square-shape {
    margin-top: 150px;
    margin-left: 190px;
  }
}

@media (min-width: 906px) and (max-width: 1100px) {
  .greeting-image-div > * {
    margin-top: 80px;
    margin-left: 100px;
  }

  .square-shape {
    margin-top: 180px;
    margin-left: 200px;
  }
}

@media (min-width: 1101px) and (max-width: 1260px) {
  .greeting-image-div > * {
    margin-top: 50px;
    margin-left: 100px;
  }

  .square-shape {
    margin-top: 150px;
    margin-left: 220px;
  }
}
